import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import "../../../assets/stylesheets/invoicesStyles.css";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import DeleteDialog from "./DeleteDialog";
import DeleteUndo from "./DeleteUndo";

export const AccordionTable = ({
  item,
  index_0,
  index_1,
  onPriceChange,
  onPartialChange,
  tempPriceChange,
  insurance,
  treatment,
}) => {
  const { t } = useTranslation();

  const [product, setProduct] = useState(item.title);
  const [chequeado, setChequeado] = useState(true);
  const [price, setPrice] = useState(item.price);
  const [qty, setQty] = useState(1);
  const [dialog, setDialog] = useState(false);
  const [show, setShow] = useState(true);
  const [undo, setUndo] = useState(false);

  const handleShow = () => {
    onPriceChange(index_0, index_1, false);

    setShow(false);
    setChequeado(false);
    setDialog(false);
    setUndo(true);
  };

  const handleUndo = () => {
    onPriceChange(index_0, index_1, true);

    setChequeado(true);
    setShow(true);
    setUndo(false);
  };

  const handlePriceChange = (index_0, index_1, event) => {
    const value = event.target.value;
    setPrice(value);
    const finalPrice = parseFloat(value) * parseFloat(qty);
    if (!isNaN(finalPrice)) {
      tempPriceChange(finalPrice, index_1);
    } else {
      tempPriceChange(0, index_1);
    }
  };

  const handleQtyChange = (event) => {
    const value = event.target.value;
    setQty(value);
    const finalPrice = parseFloat(value) * parseFloat(price);
    if (!isNaN(finalPrice)) {
      tempPriceChange(finalPrice, index_1);
    } else {
      tempPriceChange(0, index_1);
    }
  };

  return (
    <>
      {show ? (
        <>
          <tr>
            <td className="table-content-text">{parseInt(index_1) + 1}</td>
            <td className="table-content-text">{item.rate}</td>
            <td className="table-content-text">
              {item.title.replace(` ${treatment} Version`, "")}
            </td>
            <td className="table-content-text text-end">
              <TextField
                id="price"
                type="number"
                name="price"
                InputProps={{ inputProps: { min: 1, max: 10000, step: 0.01 } }}
                value={price}
                onChange={(event) => handlePriceChange(index_0, index_1, event)}
                className="inputs-alingment"
              />
            </td>
            <td className="table-content-text text-end">
              <TextField
                id="qty"
                type="number"
                name="qty"
                InputProps={{ inputProps: { min: 1, max: 100 } }}
                value={qty}
                onChange={(event) => handleQtyChange(event)}
                className="inputs-alingment"
              />
            </td>
            <td className="table-content-text text-end">
              {isNaN(parseFloat(price) * parseFloat(qty))
                ? 0
                : (parseFloat(price) * parseFloat(qty))
                    .toFixed(2)
                    .replace(".", ",")}
            </td>
            <td>
              {chequeado ? (
                <Delete
                  className="icon"
                  onClick={() => {
                    setDialog(true);
                  }}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <span onClick={() => setDialog(false)}>
                  <h4 className="restore-text">
                    {t("InvoiceAccordionTable.0")}
                  </h4>
                </span>
              )}
            </td>
          </tr>
        </>
      ) : null}
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleShow={handleShow}
        product={product}
      />
      <DeleteUndo
        undo={undo}
        setUndo={setUndo}
        handleUndo={handleUndo}
        product={product}
      />
    </>
  );
};

export default AccordionTable;
