import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionTable from "./AccordionTable";
import { TrackableLink } from "../../../utils/helper";
import "../../../assets/stylesheets/invoicesStyles.css";

export const CitationsAccordion = ({
  index_0,
  citation,
  citations,
  setCitations,
  insurance,
  handleFuncionDePrueba,
  handleRefresh,
  onProductChange,
  onPartialChange,
}) => {
  // translations
  const { t } = useTranslation();
  // data
  const [price, setPrice] = useState();

  useEffect(() => {
    var newPrice = 0;
    citation.products.map(
      (item, i) => (newPrice = newPrice + parseFloat(item.price))
    );
    setPrice(newPrice);
  }, [setPrice]);

  //FIXME...
  const onPriceChange = (index_0, index_1, state) => {
    onProductChange(index_0, index_1, state);

    var newPrice = 0;

    citation.products.map((item, i) => {
      if (item.toinvoice) {
        newPrice = newPrice + parseFloat(item.price);
      }
    });

    setPrice(newPrice);
  };

  const tempPriceChange = (finalPrice, index_1) => {
    let currentCitations = [...citations];
    currentCitations[index_0].products[index_1]["price"] =
      parseFloat(finalPrice);

    let initialPrice = 0;

    currentCitations[index_0].products.map((product, index_1) => {
      initialPrice = parseFloat(initialPrice) + parseFloat(product.price);
    });

    // console.log("INFO FINAL: ", currentCitations);
    setCitations(currentCitations);
    setPrice(initialPrice);
    handleRefresh();
  };

  const hasAdditionalProducts = (citation) => {
    try {
      const additionalProductsArray = citation.adittionalProducts;
      return (
        Array.isArray(additionalProductsArray) &&
        additionalProductsArray.length > 0
      );
    } catch (error) {
      return false;
    }
  };

  const hasProducts = hasAdditionalProducts(citation);

  return (
    <div className="diagnosis-accordion">
      <Accordion className="invoice-patient-accordion-master">
        <AccordionSummary
          className="invoice-patient-accordion-summary"
          expandIcon={<ExpandMoreIcon style={{ color: "#38baff" }} />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          style={{ zIndex: "99" }}
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                value={index_0}
                onChange={(e) => handleFuncionDePrueba(index_0, e)}
                checked={citation.toinvoice}
                //checked={chequeado}
                style={{ color: "var(--primary-color)" }}
              />
            }
            label={
              <div className="d-flex align-items-center">
                <span className="subtitles-patients border-end">
                  {new Date(citation.start).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    timeZone: "UTC",
                  })}
                  &nbsp;
                </span>
                <span className="border-end">
                  &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="var(--primary-color)"
                    class="bi bi-clock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>
                  <span className="key-text-box">
                    &nbsp;
                    {new Date(citation.start).toLocaleString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZone: "UTC",
                    })}
                    &nbsp;
                  </span>
                </span>
                <span className="subtitles-patients border-end">
                  &nbsp;{citation.treatment}&nbsp;
                </span>
                <Link
                  className="blue-anchors border-end"
                  id="view_appointment_invoices"
                  onClick={() => TrackableLink("view_appointment_invoices")}
                  style={{ marginLeft: "33px" }}
                  to={{
                    pathname: `/citation/${btoa(citation.citationID)}`,
                    state: {
                      idInvoice: citation.patientID,
                    },
                  }}
                >
                  {t("InvoiceCitationsAccordion.7")}&nbsp;&nbsp;
                  {/* AGREGAR view and add adittionals */}
                </Link>

                {hasProducts && (
                  <span className="key-text-box fw-bold border-end">
                    &nbsp;&nbsp; {t("InvoiceProductPicker.0")}
                    &nbsp;&nbsp;
                  </span>
                )}

                <span className="key-text-box ">
                  &nbsp;&nbsp; {t("ViewCitation.Did this patient showed up?")}
                  &nbsp;&nbsp;
                  {citation.showed === true
                    ? t("ViewCitation.Yes")
                    : citation.showed === false
                    ? t("ViewCitation.No")
                    : t("InvoiceCitationsAccordion.8")}
                  &nbsp;&nbsp;
                </span>
              </div>
            }
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="textSecondary">
            <table class="table table-striped tables">
              <thead>
                <tr>
                  <th class="table-header-text" style={{ width: "5%" }}>
                    {" "}
                    {t("InvoiceCitationsAccordion.0")}
                  </th>
                  <th class="table-header-text" style={{ width: "10%" }}>
                    {t("InvoiceCitationsAccordion.1")}
                  </th>
                  <th class="table-header-text" style={{ width: "45%" }}>
                    {t("InvoiceCitationsAccordion.2")}
                  </th>
                  <th class="table-header-text text-end">
                    {t("InvoiceCitationsAccordion.3")}
                  </th>
                  <th class="table-header-text text-end">
                    {t("InvoiceCitationsAccordion.4")}
                  </th>
                  <th class="table-header-text text-end">
                    {t("InvoiceCitationsAccordion.5")}
                  </th>
                  <th class="table-header-text" style={{ width: "7%" }}></th>
                </tr>
              </thead>
              <tbody>
                {citation.products.map((item, index_1) => (
                  <AccordionTable
                    item={item}
                    index_0={index_0}
                    index_1={index_1}
                    onPriceChange={onPriceChange}
                    onPartialChange={onPartialChange}
                    tempPriceChange={tempPriceChange}
                    insurance={insurance}
                    treatment={citation.treatment}
                  />
                ))}
              </tbody>
              <tfoot>
                <th class="table-header-text"></th>
                <th class="table-header-text"></th>
                <th class="table-header-text"></th>
                <th class="table-header-text"></th>
                <th class="table-header-text text-end">
                  {t("InvoiceCitationsAccordion.6")}
                </th>
                <th
                  class="table-header-text text-end"
                  style={{ paddingRight: "8px" }}
                >
                  {parseFloat(price).toFixed(2).replace(".", ",")}
                </th>
                <th class="table-header-text"></th>
              </tfoot>
            </table>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CitationsAccordion;
