import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeunload } from "react-beforeunload";
import { Card, Modal } from "react-bootstrap";
import { MenuItem, Select } from "@mui/material";
import edit_clock from "../../../assets/icons/edit_clock.png";
import GetUserToken from "../../../utils/GetUserToken";
import Alert from "../../alerts/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FormControl,
  TextField,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CitationReminderHelper from "../../../components/reminders/CitationReminderHelper";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const AdditionalsProducts = ({
  id,
  patientID,
  handleCallbackAdditionalProducts,
  bundles,
  name,
  patientEmail,
  invoiced,
  currentAdditional,
}) => {
  const { t } = useTranslation();
  const userToken = GetUserToken();
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [show, setShow] = useState(false);
  const [last, setLast] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [save, setSave] = useState(false);
  const [fieldOne, setFieldOne] = useState([]);
  const [selectedAdittionalTreatment, setSelectedAdittionalTreatment] =
    useState("");
  const [hovering, setHovering] = useState(false);

  const icon = (
    <CheckBoxOutlineBlankIcon
      fontSize="small"
      color="primary"
      style={{ color: "#38baff" }}
    />
  );

  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      color="primary"
      style={{ color: "#38baff" }}
    />
  );

  const handleProductChange = (e, value) => {
    if (value?.length > fieldOne?.length) {
      // console.log('adding: ', value.at(-1))
      setFieldOne(value);
      setSelectedAdittionalTreatment(value.at(-1).title);
      setShow(true);
      setSave(true);
    } else {
      setFieldOne(value);
      setSave(true);
    }
  };

  useBeforeunload((event) => {
    if (save !== false && !invoiced) {
      event.preventDefault();
    }
  });

  const fetchData = async () => {
    //BUG BACK en algunos casos devuelve null o "null"
    handleCallbackAdditionalProducts([false, "No changes"]);
    setLoading(true);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
        `/citations/getAdditionalsProducts?patient_id=` +
        patientID,
      requestOptions
    );

    const json = await res.json();

    let data = json.adittional;
    //console.log(data);
    let filteredData = data.filter((element) => element[2] !== null);
    setFiltered(filteredData);

    let pulseData = data.find((element) => element[2] !== null);

    setLast(pulseData);

    if (pulseData) {
      handleCallbackAdditionalProducts([true, pulseData[1]]);
      setValid(true);
      //let values = pulseData[2];
      setFieldOne(JSON.parse(pulseData[2]));

      setLoading(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const body = { adittional: JSON.stringify(fieldOne) };
    fetch(process.env.REACT_APP_API_URL + `/citations/update/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setSave(false);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
        setLoading(false);
      });
  };

  const handleDateChange = (e) => {
    setFieldOne(JSON.parse(e.target.value[2]));
    setLast(e.target.value);
    setSave(true);
  };

  // Current values of citations
  const mappedValues = currentAdditional
    ? currentAdditional.map((item) => `${item.rate} - ${item.title}\n`)
    : "";

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip disableFocusListener {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 400,
      fontSize: "15px",
    },
  });

  return (
    <>
      <Card
        className="reminders-cards"
        style={{ width: "100%", borderRadius: "10px" }}
      >
        <Card.Header
          className="subtitles-patients"
          style={{
            border: "none",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "0",
            paddingLeft: "0",
          }}
        >
          <div>
            {t("CompleteAppointment.Additional therapy")}:
            <span className="subtitles-patients border-start ms-2">
              <img
                src={edit_clock}
                alt="clock"
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
              />
              {loading ? (
                <></>
              ) : (
                <>
                  {filtered.length != 0 ? (
                    <>
                      <Select
                        id="treatment"
                        name="treatment"
                        value={last}
                        onChange={handleDateChange}
                        variant="standard"
                        // disabled={invoiced}
                      >
                        {filtered.map((item, i) => (
                          <MenuItem value={item}>
                            <b>{item[0].split("T")[0]}&nbsp; /&nbsp;</b>
                            {item[1].replace("T", " at: ").slice(0, -8)}
                            &nbsp;&nbsp;
                          </MenuItem>
                        ))}
                      </Select>
                      {hovering && (
                        <span className="key-text-box">
                          <b> {t("CompleteAppointment.Reference1")}</b>
                          {t("CompleteAppointment.Reference2")}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <span className="key-text-box">
                        &nbsp;{t("Messages.No previous records")}
                      </span>
                    </>
                  )}
                </>
              )}
              <CustomWidthTooltip
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    {t("CompleteAppointment.Current values")}:
                    <br />
                    {mappedValues}
                  </span>
                }
              >
                &nbsp;
                <HelpIcon style={{ color: "var(--primary-color)" }} />
                &nbsp;
              </CustomWidthTooltip>
            </span>
          </div>
          <div>
            {save == false || invoiced ? (
              <></>
            ) : (
              <button
                className="button-citation-box-2-save"
                onClick={handleSubmit}
                style={{ padding: "2px" }}
              >
                {t("Buttons.Save")}
              </button>
            )}
          </div>
        </Card.Header>

        <Card.Body style={{ padding: "0" }}>
          <form
            onSubmit={handleSubmit}
            style={{
              borderRadius: "10px",
            }}
          >
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col mb-2 mt-2">
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Autocomplete
                        getOptionSelected={(option, value) =>
                          option.title === value.title
                        }
                        multiple
                        id="checkboxes-tags-demo"
                        disabled={invoiced}
                        options={bundles}
                        value={fieldOne}
                        onChange={(e, value) => {
                          handleProductChange(e, value);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) =>
                          option.rate + " - " + option.title
                        }
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.rate} - {option.title}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={t("CompleteAppointment.Products")}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              </>
            )}
          </form>
        </Card.Body>
      </Card>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("CompleteAppointment.New Reminder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <CitationReminderHelper
            patientName={name}
            treatments={selectedAdittionalTreatment}
            patientID={patientID}
            patientEmail={patientEmail}
            handleClose={() => setShow(false)}
            setIsAlert={setIsAlert}
          />
        </Modal.Body>
      </Modal>

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default AdditionalsProducts;
