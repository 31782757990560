import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress, FormControl, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GetUserToken from "../../utils/GetUserToken";
import Alert from "../alerts/Alert";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: "0px",
    marginRight: theme.spacing(1),
    width: 200,
  },
});

const NewPatient = (infoRoute) => {
  // auth
  const userToken = GetUserToken();
  // route
  const history = infoRoute.history;
  // translations
  const { t } = useTranslation();
  //data
  const [title, setTitle] = useState("");
  const [rate, setRate] = useState("");
  // utils
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  // Post method
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const product = {
      title,
      rate,
      is_child_product: false,
    };

    const url = process.env.REACT_APP_API_URL + "/products/create";

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
      body: JSON.stringify(product),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.id) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setLoading(false);
          setTimeout(() => {
            history.push("/edit-product/" + response.id);
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div className="frame">
        <h4 className="main-title mt-2">{t("NewProduct.0")}</h4>
        <p className="blue-anchors">
          {" "}
          <Link className="blue-anchors" to="/products">
            {" "}
            &#60; {t("NewProduct.1")}
          </Link>
        </p>

        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div className="patients-table">
            <form onSubmit={handleSubmit} className="row">
              <div className="col-4">
                <div className="form-group">
                  <FormControl className="title-inputs form-control-width">
                    <label className="title-inputs">{t("NewProduct.2")}</label>
                    <br />
                    <TextField
                      required
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      size="small"
                      variant="outlined"
                      className="input-control"
                    />
                  </FormControl>
                </div>

                <div className="form-group">
                  <FormControl className="title-inputs form-control-width">
                    <label className="title-inputs">{t("NewProduct.4")}</label>
                    <br />
                    <TextField
                      required
                      type="text"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      size="small"
                      variant="outlined"
                      className="input-control"
                    />
                  </FormControl>
                </div>

                <div className="mt-5">
                  <button
                    className={
                      isAlert.open
                        ? "blue-btn-disabled form-control-width"
                        : "blue-btn form-control-width"
                    }
                    disabled={isAlert.open}
                  >
                    {t("NewProduct.3")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        {isAlert.open && (
          <Alert
            isAlert={isAlert}
            setIsAlert={setIsAlert}
            severity={isAlert.severity}
            message={isAlert.message}
          />
        )}
      </div>
    </>
  );
};

export default withStyles(useStyles)(NewPatient);
