import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { Alert, Stack } from "@mui/material";
import InfoIcon from "@material-ui/icons/Info";
import GetUserToken from "../../utils/GetUserToken";
import InsurancePriceField from "./InsurancePriceField";
import AlertComponent from "../alerts/Alert";

const EditProduct = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  // route
  const history = infoRoute.history;
  const location = infoRoute.location;
  const productID = location.pathname.split("/").pop();
  const isEditTreatment = location.state ? true : false;
  // data
  const [insurances, setInsurances] = useState([]);
  const [prices, setPrices] = useState([]);
  const [title, setName] = useState("");
  const [rate, setFee] = useState("");
  // utils
  const [saved, setSaved] = useState(true);
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // auth
  const userToken = GetUserToken();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const url =
        process.env.REACT_APP_API_URL_V2 +
        "/products/show/" +
        infoRoute.match.params.id;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      const productInfo = json.product;
      setName(productInfo.title);
      setFee(productInfo.rate);
      setArchived(productInfo.archived);
      setPrices(json.prices);
      await fetchInsurances();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInsurances = async () => {
    setInsurances([]);
    try {
      const url = process.env.REACT_APP_API_URL + "/insurances/index/";

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      setInsurances(json.insurances);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const product = { title, rate };

    const url =
      process.env.REACT_APP_API_URL +
      "/products/update/" +
      infoRoute.match.params.id;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
      body: JSON.stringify(product),
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setTimeout(() => {
            if (isEditTreatment) {
              history.push("/treatments");
            } else {
              history.push("/products");
            }
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  const handleArchive = (e) => {
    setLoading(true);
    e.preventDefault();
    const product = { archived: !archived };

    const url =
      process.env.REACT_APP_API_URL +
      "/products/update/" +
      infoRoute.match.params.id;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
      body: JSON.stringify(product),
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setTimeout(() => {
            history.push("/products");
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  const getInsurancePrice = (insurance) => {
    let filtered = prices.filter((p) => p.insurance_id == insurance);
    if (filtered.length > 0) {
      let lastElementIndex = filtered.length - 1;
      let finalPrice = filtered[lastElementIndex]["price"];
      return finalPrice;
    } else {
      //
      return 0;
    }
  };

  const handlePriceChange = async (insurance, price) => {
    try {
      const url =
        process.env.REACT_APP_API_URL +
        "/products/change_price/" +
        infoRoute.match.params.id;

      const raw = {
        product: infoRoute.match.params.id,
        insurance: insurance,
        price: price,
      };

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
        body: JSON.stringify(raw),
      };

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        setSaved(true);
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        fetchData();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    }
  };

  return (
    <>
      <div className="frame" style={{ width: "calc(100% - 80px)" }}>
        <div className="row">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ paddingBottom: "6%" }}
          >
            <div>
              <h4 className="main-title-treatments">{t("EditProduct.6")}</h4>
              <p className="blue-anchors">
                <Link
                  className="blue-anchors"
                  to={
                    isEditTreatment
                      ? `/edit-treatment/${location.state.idTreatment}`
                      : "/products"
                  }
                >
                  &#60;{" "}
                  {isEditTreatment ? t("EditProduct.9") : t("EditProduct.7")}
                </Link>
              </p>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12 col-lg-5">
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="name" className="title-treatments">
                        {t("EditProduct.0")}
                      </label>
                      <input
                        required
                        disabled={true}
                        type="text"
                        value={title}
                        onChange={(e) => setName(e.target.value)}
                        className="input-control"
                        style={{ color: "gray", fontStyle: "italic" }}
                      />
                      <Stack sx={{ mt: 1 }}>
                        <Alert
                          severity="warning"
                          style={{ alignItems: "center" }}
                          className="title-inputs"
                        >
                          {t(
                            "Messages.At the moment, you cannot change the name, if you wish to do so you must create a new entry"
                          )}
                        </Alert>
                      </Stack>
                    </div>

                    <div className="form-group">
                      <label htmlFor="duration" className="title-treatments">
                        {t("EditProduct.8")}
                      </label>
                      <input
                        required
                        type="text"
                        value={rate}
                        onChange={(e) => setFee(e.target.value)}
                        className="input-control"
                      />
                    </div>

                    {isEditTreatment && (
                      <div className="form-group">
                        <InfoIcon />
                        <span>{t("Messages.Original price")}</span>
                        <br />
                        <InfoIcon style={{ color: "red" }} />
                        <span style={{ color: "red" }}>
                          {t("Messages.Modified price")}
                        </span>
                      </div>
                    )}

                    <div className="d-flex justify-content-center mt-3">
                      {archived ? (
                        <button
                          className="blue-btn"
                          onClick={handleArchive}
                          style={{ width: "auto" }}
                        >
                          {t("EditProduct.1")}
                        </button>
                      ) : (
                        <button
                          className="blue-btn"
                          onClick={handleArchive}
                          style={{ width: "auto" }}
                        >
                          {t("EditProduct.2")}
                        </button>
                      )}

                      <div>
                        <button
                          className="blue-btn"
                          onClick={handleSubmit}
                          style={{ width: "auto" }}
                        >
                          {t("EditProduct.3")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  {insurances
                    .sort((a, b) => a["id"] - b["id"])
                    .map((item, i) => (
                      <InsurancePriceField
                        key={i}
                        item={item}
                        getInsurancePrice={getInsurancePrice}
                        handlePriceChange={handlePriceChange}
                        setSaved={setSaved}
                        prices={prices}
                        productID={productID}
                        isEditTreatment={isEditTreatment}
                      />
                    ))}
                </div>
                {/* <div className="col-1" style={{ marginTop: "4px" }}>
                  {prices.map((item, i) => (
                    <div key={i}>
                      {item.product_id !== parseInt(productID) ? (
                        <div>
                          <Tooltip
                            title={t("Messages.Original price")}
                            style={{ marginTop: "14px" }}
                          >
                            <IconButton aria-label="info">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div>
                          <Tooltip
                            title={t("Messages.Modified price")}
                            style={{ marginTop: "14px" }}
                          >
                            <IconButton aria-label="info">
                              <InfoIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default EditProduct;
