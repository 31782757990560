// datadog
import { datadogRum } from "@datadog/browser-rum";

export const TrackableLink = (id) => {
  datadogRum.addAction(id);
};
// end datadog

// terms and conditions link
export const getLinkAsOfAppLanguage = (language) => {
  const base_url = "https://www.tcmassist.org/";
  switch (language) {
    case "en":
      return `${base_url}en/agb/`;
    case "es":
      return `${base_url}es/agb/`;
    default:
      return `${base_url}agb_patient/`;
  }
};
// end terms and conditions link

// herbs
const stringToArray = (string) => JSON.parse(string.replace(/'/g, '"'));

export const formatHerbTranslations = (herbs_translations, submit) => {
  let herbs_translations_copy = JSON.parse(JSON.stringify(herbs_translations));

  let format = herbs_translations_copy.map((obj) => {
    if (obj["actions_and_indications"]) {
      obj["actions_and_indications"] = submit
        ? JSON.stringify(obj["actions_and_indications"])
        : stringToArray(obj["actions_and_indications"]);
    }
    if (obj["dosage"]) {
      obj["dosage"] = submit
        ? JSON.stringify(obj["dosage"])
        : stringToArray(obj["dosage"]);
    }
    if (obj["major_combinations"]) {
      obj["major_combinations"] = submit
        ? JSON.stringify(obj["major_combinations"])
        : stringToArray(obj["major_combinations"]);
    }
    return obj;
  });
  return format;
};

export const handleViewHerbTranslations = (
  herbTranslation,
  idLanguage,
  value,
  sub_value,
  isArray
) => {
  const translation = herbTranslation.filter(
    (element) => element.languages_id === idLanguage
  );
  if (translation.length !== 0) {
    const obj_translation = translation[0];
    if (sub_value) {
      return obj_translation[value]
        ? obj_translation[value][sub_value]
          ? obj_translation[value][sub_value]
          : "no data"
        : "no data";
    } else if (isArray) {
      return obj_translation[value]
        ? obj_translation[value]
        : [{ column_one: "", column_two: "" }];
    } else {
      return obj_translation[value] ? obj_translation[value] : "no data";
    }
  } else {
    if (isArray) {
      return [{ column_one: "", column_two: "" }];
    } else {
      return "no data";
    }
  }
};

export const updatedHerbTranslation = (
  idLanguage,
  name,
  value,
  sub_value,
  index,
  herbTranslation,
  herbs_id
) => {
  let herbs_translations_copy = JSON.parse(JSON.stringify(herbTranslation));
  let translation = herbs_translations_copy.filter(
    (translation) => translation.languages_id === idLanguage
  );

  if (translation.length !== 0) {
    herbs_translations_copy.reduce((acc, curr) => {
      if (curr.languages_id === idLanguage) {
        const element = curr;
        if (sub_value) {
          if (index >= 0) {
            if (name === "major_combinations") {
              if (element[name] && element[name] !== null) {
                let array = element[name];
                let item = array[index];
                if (item) {
                  if (sub_value === "action") {
                    element[name][index] = {
                      ...item,
                      action: value,
                    };
                  } else {
                    element[name][index] = {
                      ...item,
                      ingredients: value,
                    };
                  }
                } else {
                  if (sub_value === "action") {
                    array.push({
                      ...item,
                      action: value,
                    });
                  } else {
                    array.push({
                      ...item,
                      ingredients: value,
                    });
                  }
                }
              } else {
                let array = [];
                array.push(
                  sub_value === "action"
                    ? { action: value }
                    : { ingredients: value }
                );
                element[name] = array;
              }
            } else {
              if (element[name] && element[name] !== null) {
                let array = element[name];
                let item = array[index];
                if (item) {
                  if (sub_value === "Actions") {
                    element[name][index] = {
                      ...item,
                      Actions: value,
                    };
                  } else {
                    element[name][index] = {
                      ...item,
                      "Indications/Syndromes": value,
                    };
                  }
                } else {
                  if (sub_value === "Actions") {
                    array.push({
                      ...item,
                      Actions: value,
                    });
                  } else {
                    array.push({
                      ...item,
                      "Indications/Syndromes": value,
                    });
                  }
                }
              } else {
                let array = [];
                array.push(
                  sub_value === "Actions"
                    ? { Actions: value }
                    : { "Indications/Syndromes": value }
                );
                element[name] = array;
              }
            }
          } else {
            if (element[name] && element[name] !== null) {
              element[name][sub_value] = value;
            } else {
              let newValue = "";
              switch (sub_value) {
                case "normal":
                  newValue = { normal: value };
                  break;
                case "tincture":
                  newValue = { tincture: value };
                  break;
                case "powdersorpills":
                  newValue = { powdersorpills: value };
                  break;
                case "administration":
                  newValue = { administration: value };
                  break;
                default:
                  newValue = { processing: value };
                  break;
              }
              element[name] = newValue;
            }
          }
        } else {
          element[name] = value;
        }
        acc.push(element);
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  } else {
    const element = {
      herbs_id: Number(herbs_id),
      languages_id: idLanguage,
    };
    let array = [];
    if (sub_value) {
      if (index >= 0) {
        if (name === "major_combinations") {
          array.push(
            sub_value === "action" ? { action: value } : { ingredients: value }
          );
          element[name] = array;
        } else {
          array.push(
            sub_value === "Actions"
              ? { Actions: value }
              : { "Indications/Syndromes": value }
          );
          element[name] = array;
        }
      } else {
        element[name] =
          sub_value === "normal" ? { normal: value } : { tincture: value };
      }
    } else {
      element[name] = value;
    }
    herbs_translations_copy.push(element);
  }
  return herbs_translations_copy;
};
// end herbs
