import React, { useState } from "react";
import { LoginStep0 } from "./login/LoginStep0";
import { LoginStep1 } from "./login/LoginStep1";

const Component = ({ setValidToken }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(0);
  // data
  const [accessToken, setAccessToken] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [key, setKey] = useState("");
  const [code, setCode] = useState("");

  const setToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
  };

  const handlePreSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const credentials = {
      email,
      password,
    };

    const token = await fetch(
      process.env.REACT_APP_API_URL + "/users/prelogin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(credentials),
      }
    ).then((result) => result.json());

    if (token.code == "400") {
      setKey(token.token);
      setAccessToken(token.access_token);
      setLoading(false);
      setStep(1);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const setAuth0Token = async () => {
    // console.log("setting auth0 token... :)");
    // const encrypted = await GetAuthToken();
    localStorage.setItem("auth", "encrypted.access_token");
    // dispatch(tokenData(encrypted));
    return true;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (key === code) {
      const credentials = {
        email,
        password,
      };

      const token = await fetch(
        process.env.REACT_APP_API_URL + "/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            Authorization: accessToken,
          },
          body: JSON.stringify(credentials),
        }
      ).then((result) => result.json());

      if (token.code === "400") {
        await setAuth0Token();
        setLoading(false);
        setToken(token);
        setValidToken(token);
        // handleAuth0Login();
        if (window.location.pathname === "/login") {
          window.location.href = "/";
        }
      } else {
        setError(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      {step === 0 && (
        <LoginStep0
          error={error}
          setError={setError}
          loading={loading}
          handlePreSubmit={handlePreSubmit}
          setEmail={setEmail}
          setPassword={setPassword}
          setStep={setStep}
        />
      )}

      {step === 1 && (
        <LoginStep1
          error={error}
          setError={setError}
          loading={loading}
          code={code}
          setCode={setCode}
          handleSubmit={handleSubmit}
          setEmail={setEmail}
          setPassword={setPassword}
          setStep={setStep}
        />
      )}
    </>
  );
};

export default Component;
