import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { TbPlant2, TbPlant } from "react-icons/tb";
import { ReactComponent as BurguerIcon } from "../assets/icons/menu.svg";
import { ReactComponent as Home } from "../assets/icons/home.svg";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import { ReactComponent as Reminders } from "../assets/icons/reminders.svg";
import { ReactComponent as PatienList } from "../assets/icons/patient_list.svg";
import { ReactComponent as Invoices } from "../assets/icons/invoices.svg";
import { ReactComponent as Config } from "../assets/icons/config.svg";
import { PiChartLineUpThin } from "react-icons/pi";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "../assets/stylesheets/sidebar.css";

const Sidebar = ({ deleteToken }) => {
  // translations
  const { t } = useTranslation();
  // redux
  const state = useSelector((state) => state.user);
  // menu
  const [menuCollapse, setMenuCollapse] = useState(true);
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div
              className="closemenu"
              title="Open/Close"
              onClick={menuIconClick}
              style={{ margin: "21px 0px", padding: "8px 0px 8px 35px" }}
            >
              <BurguerIcon className="icon" />
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem
                title={t("Sidebar.Home")}
                icon={<Home className="icon" />}
              >
                <Link to="/" />
                {t("Sidebar.Home")}
              </MenuItem>

              {state.auth?.admin ? (
                <>
                  <MenuItem
                    title={t("Settings.Dashboard")}
                    icon={
                      <PiChartLineUpThin
                        size={30}
                        style={{ strokeWidth: "1" }}
                      />
                    }
                  >
                    <Link to="/dashboard" />
                    {t("Settings.Dashboard")}
                  </MenuItem>

                  <MenuItem
                    title={t("Sidebar.Calendar")}
                    icon={<Calendar className="icon" />}
                  >
                    <Link to="/calendar" />
                    {t("Sidebar.Calendar")}
                  </MenuItem>

                  <MenuItem
                    title={t("Sidebar.Reminders")}
                    icon={<Reminders className="icon" />}
                  >
                    <Link to="/reminders" />
                    {t("Sidebar.Reminders")}
                  </MenuItem>

                  <MenuItem
                    title={t("Sidebar.Patients")}
                    icon={<PatienList className="icon" />}
                  >
                    <Link to="/patients" />
                    {t("Sidebar.Patients")}
                  </MenuItem>

                  <MenuItem
                    title={t("Sidebar.Invoices")}
                    icon={<Invoices className="icon" />}
                  >
                    <Link to="/invoices" />
                    {t("Sidebar.Invoices")}
                  </MenuItem>

                  <MenuItem
                    title="Herbs"
                    icon={<TbPlant2 size={30} style={{ strokeWidth: "1" }} />}
                  >
                    <Link to="/herbs" />
                    {t("Sidebar.Herbs")}
                  </MenuItem>

                  <MenuItem
                    title={t("Sidebar.Config")}
                    icon={<Config className="icon" />}
                  >
                    <Link to="/config" />
                    {t("Sidebar.Config")}
                  </MenuItem>
                </>
              ) : (
                <>
                  {state.auth?.secretary || state.auth?.doctor ? (
                    <>
                      <MenuItem
                        title={t("Sidebar.Calendar")}
                        icon={<Calendar className="icon" />}
                      >
                        <Link to="/calendar" />
                        {t("Sidebar.Calendar")}
                      </MenuItem>

                      <MenuItem
                        title={t("Sidebar.Reminders")}
                        icon={<Reminders className="icon" />}
                      >
                        <Link to="/reminders" />
                        {t("Sidebar.Reminders")}
                      </MenuItem>

                      <MenuItem
                        title={t("Sidebar.Patients")}
                        icon={<PatienList className="icon" />}
                      >
                        <Link to="/patients" />
                        {t("Sidebar.Patients")}
                      </MenuItem>

                      {state.auth?.doctor && (
                        <MenuItem
                          title="Herbs"
                          icon={
                            <TbPlant2 size={30} style={{ strokeWidth: "1" }} />
                          }
                        >
                          <Link to="/herbs" />
                          {t("Sidebar.Herbs")}
                        </MenuItem>
                      )}

                      {state.auth?.secretary && (
                        <MenuItem
                          title={t("Sidebar.Invoices")}
                          icon={<Invoices className="icon" />}
                        >
                          <Link to="/invoices" />
                          {t("Sidebar.Invoices")}
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <>
                      {/* links for users here */}
                      <MenuItem
                        title={t("Sidebar.Invoices")}
                        icon={<Invoices className="icon" />}
                      >
                        <Link to="/invoices" />
                        {t("Sidebar.Invoices")}
                      </MenuItem>
                    </>
                  )}
                </>
              )}
            </Menu>
          </SidebarContent>
          {/*
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem 
                onClick={deleteToken}
                icon={<FiLogOut />}
                >
                Logout</MenuItem>
            </Menu>
          </SidebarFooter>
          */}
        </ProSidebar>
      </div>
    </>
  );
};

export default Sidebar;
