import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../assets/stylesheets/invoicesStyles.css";
import Preview from "./Preview";
import { useSelector } from "react-redux";
import GetUserToken from "../../../utils/GetUserToken";
import AlertComponent from "../../alerts/Alert";

export const SummaryButtons = ({
  total,
  adittionalproducts,
  citations,
  onSubmit,
  ready,
  creating,
  preview,
  success,
  download,
  downloading,
  setDownloading,
  patient,
  diagnosis,
  westlicheDiagnosis,
  date,
  duedate,
  onPreview,
}) => {
  const state = useSelector((state) => state.companyStore.one.data);
  const userToken = GetUserToken();

  const { t } = useTranslation();

  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const onDownload = async () => {
    // Construct the URL to request the PDF file
    const pdfUrl = `${process.env.REACT_APP_API_URL_V2}/invoices/generate/${download}`;
    setDownloading(true);

    setIsAlert({
      open: true,
      severity: "info",
      message: t("Messages.Invoice opening and download, please wait"),
    });

    try {
      // Fetch the PDF from the backend
      const response = await fetch(pdfUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF.");
      }
      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = downloadUrl;

      // Set the file name, adjust this depending on your backend response
      link.download = `RE_TCM_${download}.pdf`;
      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the temporary URL
      window.URL.revokeObjectURL(downloadUrl);
      //
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // preview
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    onPreview();
    setShow(true);
  };

  const filteredProducts = adittionalproducts.filter(
    (item) => item.toinvoice == true
  );

  return (
    <>
      <div className="invoice-buttons-card">
        <h4 className="invoice-buttons-title">
          {t("InvoiceSummaryButtons.0")}:
        </h4>
        <div className="invoice-buttons-separator">
          <hr />
        </div>
        <h4 className="invoice-buttons-total">
          {parseFloat(total).toFixed(2).replace(".", ",")}&nbsp;
          {/* {state.currency && state.currency} */}
          {state ? state.currency : ""}
        </h4>
        <div className="invoice-button-container">
          {preview ? (
            <>
              <button
                onClick={handleShow}
                className="invoice-generate-button mb-3"
                id="preview_invoice"
              >
                {t("InvoiceSummaryButtons.1")}
              </button>
            </>
          ) : (
            <>
              <button className="invoice-generate-button-disabled mb-3">
                {t("InvoiceSummaryButtons.1")}
              </button>
            </>
          )}
          {success ? (
            <>
              {downloading ? (
                <>
                  <button className="invoice-generate-button-disabled">
                    {t("InvoiceSummaryButtons.2")}...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="invoice-generate-button"
                    id="download_invoice"
                    onClick={() => onDownload()}
                  >
                    {t("InvoiceSummaryButtons.2")}
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              {creating ? (
                <>
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                </>
              ) : (
                <>
                  {ready ? (
                    <>
                      <button
                        className="invoice-generate-button"
                        id="generate_invoice"
                        onClick={() => onSubmit()}
                      >
                        {t("InvoiceSummaryButtons.3")}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        disabled
                        className="invoice-generate-button-disabled"
                      >
                        {t("InvoiceSummaryButtons.3")}
                      </button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        {/* <Modal.Header  style={{background: '#F4F5FA'}} >
                <Modal.Title >
                    Invoice Summary
                </Modal.Title>
            </Modal.Header> */}
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <Preview
            total={total}
            adittionalproducts={filteredProducts}
            citations={citations}
            patient={patient}
            diagnosis={diagnosis}
            date={date}
            duedate={duedate}
            westlicheDiagnosis={westlicheDiagnosis}
          />
        </Modal.Body>
      </Modal>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default SummaryButtons;
