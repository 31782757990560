import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import treatmentsStyles from "../../assets/stylesheets/treatmentsStyles.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import EditDoctor from "./EditDoctor";
import GetUserToken from "../../utils/GetUserToken";
import Table from "./DoctorsTable";

const DoctorsList = ({
  isOnboarding = false,
  handleStepComplete = () => {},
}) => {
  // translation
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // auth
  const userToken = GetUserToken();
  // data
  const [data, setData] = useState();
  const [users, setUsers] = useState();
  const [currentDoctor, setcurrentDoctor] = useState("");
  // utils
  const [loading, setLoading] = useState(true);
  const [editshow, seteditShow] = useState(false);

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: <>ID</>,
            accessor: "id",
            Cell: ({ row }) => (
              <>
                {row.original.archived ? (
                  <span style={{ color: "gray", fontStyle: "italic" }}>
                    {row.original.id}
                  </span>
                ) : (
                  row.original.id
                )}
              </>
            ),
          },
          {
            Header: <>{t("QuizList.Name")}</>,
            accessor: "name",
            Cell: ({ row }) => (
              <>
                {row.original.archived ? (
                  <span style={{ color: "gray", fontStyle: "italic" }}>
                    {row.original.name}
                  </span>
                ) : (
                  row.original.name
                )}
              </>
            ),
          },
          {
            Header: <>{t("DoctorsList.1")}</>,
            accessor: "color",
            Cell: ({ row }) => (
              <>
                {row.original.archived ? (
                  <span className="badge" style={{ background: "gray" }}>
                    {row.original.color}
                  </span>
                ) : (
                  <span
                    className="badge"
                    style={{ background: row.original.color }}
                  >
                    {row.original.color}
                  </span>
                )}
              </>
            ),
          },

          {
            Header: <>{t("DoctorsList.2")}</>,
            accessor: "active",
            Cell: ({ row }) => (
              <>
                {row.original.archived ? (
                  <span style={{ color: "gray", fontStyle: "italic" }}>
                    {t("ProductList.1")}
                  </span>
                ) : (
                  <> {t("ProductList.2")}</>
                )}
              </>
            ),
          },
          {
            Header: <>{t("DoctorsList.3")}</>,
            accessor: "roles",
            Cell: ({ row }) => {
              //console.log("roles row", row)
              return (
                <>
                  {row.original.archived ? (
                    <span className="badge" style={{ background: "gray" }}>
                      N/A
                    </span>
                  ) : (
                    <>
                      {row.original?.user?.roles?.includes("admin") && (
                        <span
                          className="badge"
                          style={{ background: "#3a86ff" }}
                        >
                          {t("EditDoctor.Admin")}
                        </span>
                      )}
                      &nbsp;
                      {row.original?.user?.roles?.includes("doctor") && (
                        <span
                          className="badge"
                          style={{ background: "#e56b6f" }}
                        >
                          {t("EditDoctor.Therapist")}
                        </span>
                      )}
                      &nbsp;
                      {row.original?.user?.roles?.includes("secretary") && (
                        <span
                          className="badge"
                          style={{ background: "#52b788" }}
                        >
                          {t("EditDoctor.Secretary")}
                        </span>
                      )}
                    </>
                  )}
                </>
              );
            },
          },
          {
            Header: " ",
            accessor: "edit",
            Cell: ({ row }) => (
              <div style={{ cursor: "pointer", marginRight: "10px" }}>
                <Pencil
                  className="icon"
                  onClick={() => editDoctor(row.original.id)}
                />
              </div>
            ),
          },
        ],
      },
    ],
    [currentLanguage, users]
  );

  const fetchData = async () => {
    const url = process.env.REACT_APP_API_URL + "/doctors";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };
    const res = await fetch(url, requestOptions);
    const json = await res.json();
    //console.log(json)

    setData(json);
    // setUsers(json.clinicUsers);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleeditClose = () => {
    seteditShow(false);
  };
  const handleeditShow = () => seteditShow(true);

  const editDoctor = (id) => {
    handleeditShow();
    setcurrentDoctor(id);
  };

  return (
    <>
      <div className={!isOnboarding ? "frame pb-3" : ""}>
        <div className={!isOnboarding ? "" : "text-center"}>
          <h4 className="main-title mt-2">{t("DoctorsList.0")}</h4>
        </div>
        <br />

        <div
          style={{
            marginRight: "50px",
            marginLeft: !isOnboarding ? "0px" : "50px",
          }}
        >
          <div className="container-fluid treatments-table">
            {loading || !data ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Table
                columns={columns}
                data={data}
                isOnboarding={isOnboarding}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        show={editshow}
        onHide={handleeditClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>
            {t("DoctorsList.5")} {t("DoctorsList.0")} # {currentDoctor}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <EditDoctor
            id={currentDoctor}
            handleeditClose={handleeditClose}
            fetchData={fetchData}
            isOnboarding={isOnboarding}
            handleStepComplete={handleStepComplete}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DoctorsList;
