import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
//.....
import treatmentsStyles from "../../assets/stylesheets/treatmentsStyles.css";
import CurrentInvoices from "./invoiceslist/CurrentInvoices";
import CurrentPatients from "./invoiceslist/CurrentPatients";
import GetUserToken from "../../utils/GetUserToken";
import { useTranslation } from "react-i18next";
import ButtonGroupComponent from "../reusableComponents/ButtonGroup";
import AlertComponent from "../alerts/Alert";

export const InvoicesList = () => {
  // translations
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // data
  const [data, setData] = useState();
  const [patients, setPatients] = useState();
  // utils
  const [loading, setLoading] = useState(true);
  const [loadinginvoices, setLoadinginvoices] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    getInvoices();
    getPatients();
  }, [setData]);

  const getInvoices = async () => {
    const url = process.env.REACT_APP_API_URL + "/invoices/index";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();

    let patientsArray = [];
    json.invoices.map((item, i) => {
      let currentPatient = JSON.parse(item.patient);
      patientsArray.push({
        id: item.id,
        lastname: currentPatient["lastname"],
        firstname: currentPatient["firstname"],
        diagnosis: item.diagnostic,
        date: item.date,
        total: item.total,
        status: item.status,
        paid_at: item.paid_at,
        due_date: item.due_date,
        updated_at: item.updated_at,
      });
    });
    setData(patientsArray);
    setLoadinginvoices(false);
  };

  const getPatients = async () => {
    const url =
      process.env.REACT_APP_API_URL_V2 + "/invoices/get_patients_to_invoice";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);

    const json = await res.json();
    // console.log(json);
    setPatients(json.patients);
    setLoading(false);
  };

  const downloadInvoice = async (event) => {
    setDownloading(true);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const url =
      process.env.REACT_APP_API_URL_V2 +
      "/invoices/generate/" +
      event.target.id;

    if (event.target.id) {
      try {
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = downloadUrl;
        console.log(link);
        // Set the file name, you may want to adjust this depending on your backend response
        link.download = `RE_TCM_${event.target.id}.pdf`;

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up the temporary URL
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Error, please refresh and try again"),
        });
      }
    } else {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Error, please refresh and try again"),
      });
    }

    setDownloading(false);
  };

  const markAsPaid = async (event) => {
    try {
      setLoadinginvoices(true);
      var url =
        process.env.REACT_APP_API_URL +
        "/invoices/update_status?id=" +
        event.target.id +
        "&status=paid";
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      };
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        getInvoices();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoadinginvoices(false);
    }
  };

  const markAsPartialPaid = async (event) => {
    try {
      setLoadinginvoices(true);
      var url =
        process.env.REACT_APP_API_URL +
        "/invoices/update_status?id=" +
        event.target.id +
        "&status=partialpaid";
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      };
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        getInvoices();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoadinginvoices(false);
    }
  };

  const markAsOverdue = async (event) => {
    try {
      setLoadinginvoices(true);
      var url =
        process.env.REACT_APP_API_URL +
        "/invoices/update_status?id=" +
        event.target.id +
        "&status=warning";
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      };
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        getInvoices();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoadinginvoices(false);
    }
  };

  const markAsUnpaid = async (event) => {
    try {
      setLoadinginvoices(true);
      var url =
        process.env.REACT_APP_API_URL +
        "/invoices/update_status?id=" +
        event.target.id +
        "&status=unpaid";
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      };
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        getInvoices();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoadinginvoices(false);
    }
  };

  const markAsCancel = async (event) => {
    try {
      setLoadinginvoices(true);
      var url =
        process.env.REACT_APP_API_URL +
        "/invoices/update_status?id=" +
        event.target.id +
        "&status=cancel";
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      };
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        getInvoices();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoadinginvoices(false);
    }
  };

  return (
    <>
      {/* Support */}
      <ButtonGroupComponent />

      <div className="frame">
        <h4 className="main-title mt-2">{t("InvoiceList.0")}</h4>

        <Tabs
          defaultActiveKey="patients"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="patients" title={t("InvoiceList.1")}>
            <CurrentPatients patients={patients} loading={loading} />
          </Tab>
          <Tab eventKey="invoices" title={t("InvoiceList.2")}>
            <CurrentInvoices
              downloading={downloading}
              loading={loadinginvoices}
              setLoading={setLoadinginvoices}
              data={data}
              downloadInvoice={downloadInvoice}
              markAsPaid={markAsPaid}
              markAsPartialPaid={markAsPartialPaid}
              markAsOverdue={markAsOverdue}
              markAsCancel={markAsCancel}
              markAsUnpaid={markAsUnpaid}
            />
          </Tab>
        </Tabs>
        <br />
      </div>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}

      {/*       <Modal show={warning} backdrop="static">
        <Modal.Header>
          <Modal.Title>{t("InvoiceList.3")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("InvoiceList.4")} <b>{t("InvoiceList.5")}</b>, {t("InvoiceList.6")}{" "}
          <b>{t("InvoiceList.7")}</b>. {t("InvoiceList.8")}.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => (window.location.href = "/")}
          >
            {t("InvoiceList.9")}
          </Button>
          <Button variant="primary" onClick={() => setWarning(false)}>
            {t("InvoiceList.10")}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
