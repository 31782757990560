import React from "react";
import { ButtonGroup } from "@mui/material";
import { NewReminderHelper } from "../reminders/NewReminderHelper";
import SupportButton from "./SupportButton";

const ButtonGroupComponent = ({ visible, setUpdateReminders }) => {
  return (
    <ButtonGroup
      variant="contained"
      orientation="vertical"
      className="button-group"
    >
      {visible && (
        <NewReminderHelper
          setUpdateReminders={setUpdateReminders ? setUpdateReminders : false}
        />
      )}
      <SupportButton />
    </ButtonGroup>
  );
};

export default ButtonGroupComponent;
