import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Button, Grid } from "@material-ui/core";
import {
  CalendarToday as CalendarTodayIcon,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  List as ListIcon,
  Notifications as NotificationsIcon,
  Receipt as ReceiptIcon,
  LocalOffer as LocalOfferIcon,
} from "@material-ui/icons";
import patientBooking from "../../assets/images/patient-booking.webp";
import patientAppointmentFile from "../../assets/images/patient-appointment-file.webp";
import createTimeBlock from "../../assets/images/create-time-block.webp";
import createQuiz from "../../assets/images/create-quiz.webp";
import createReminder from "../../assets/images/create-reminder.webp";
import createInvoice from "../../assets/images/create-invoice.webp";
import createAdditionalProduct from "../../assets/images/create-additional-product.webp";

const NextSteps = () => {
  // translations
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  // data
  const steps = useMemo(
    () => [
      {
        id: "patient_booking",
        title: t("NextSteps.Patient booking"),
        link: "https://www.tcmassist.org/appointments/como-crear-una-cita-por-primera-vez-siendo-paciente/",
        icon: <CalendarTodayIcon />,
        image: patientBooking,
      },
      {
        id: "patient_appointment_file",
        title: t("NextSteps.Patient appointment file"),
        link: "https://www.tcmassist.org/appointments/wie-kann-man-als-therapeut-auf-eine-termindatei-zugreifen/",
        icon: <AssignmentIcon />,
        image: patientAppointmentFile,
      },
      {
        id: "create_time_block",
        title: t("NextSteps.Create time block"),
        link: "https://www.tcmassist.org/time-blocks/wie-erstellt-man-einen-zeitblock-im-kalender/",
        icon: <AccessTimeIcon />,
        image: createTimeBlock,
      },
      {
        id: "create_quiz",
        title: t("NextSteps.Create a quiz"),
        link: "https://www.tcmassist.org/questionnaires/wie-erstellt-man-einen-fragebogen/",
        icon: <ListIcon />,
        image: createQuiz,
      },
      {
        id: "create_reminder",
        title: t("NextSteps.Create a reminder"),
        link: "https://www.tcmassist.org/reminders/wie-kann-ich-eine-erinnerung-erstellen/",
        icon: <NotificationsIcon />,
        image: createReminder,
      },
      {
        id: "create_invoice",
        title: t("NextSteps.Create an invoice"),
        link: "https://www.tcmassist.org/invoices/wie-erstellt-man-eine-rechnung/",
        icon: <ReceiptIcon />,
        image: createInvoice,
      },
      {
        id: "create_additional_product",
        title: t("NextSteps.Create an additional product"),
        link: "https://www.tcmassist.org/additional-products/wie-kann-man-zusaetzliche-produkte-erstellen/",
        icon: <LocalOfferIcon />,
        image: createAdditionalProduct,
      },
    ],
    [currentLanguage]
  );

  return (
    <div
      className="my-4"
      style={{ maxHeight: "100%", overflowY: "auto", overflowX: "hidden" }}
    >
      <Grid container justifyContent="center">
        {steps.map((step) => (
          <Grid
            item
            container
            justifyContent="center"
            sm={12}
            md={6}
            lg={4}
            xl={3}
            key={step.id}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                marginBottom: "30px",
                width: "400px",
                height: "400px",
              }}
            >
              <CardContent
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      color: "var(--primary-color)",
                      marginRight: "10px",
                    }}
                  >
                    {step.icon}
                  </div>
                  <h5 className="mb-0">{step.title}</h5>
                </div>
                <div style={{ height: "260px" }}>
                  <img
                    src={step.image}
                    alt={step.title}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    href={step.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={step.id}
                    style={{
                      marginTop: "10px",
                      backgroundColor: "var(--secondary-color)",
                      color: "#FFFFFF",
                      width: "50%",
                    }}
                  >
                    {t("NextSteps.Learn more")}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default NextSteps;
